import styles from "./OperationUnitsBanner.module.scss";
import first from "lodash/first";
import useStaticBlock from "grandus-lib/hooks/useStaticBlock";
import Image from "grandus-lib/components-atomic/image/Image";
import Link from "next/link";
import IconArrowRightSimple from "../../_others/icons/IconArrowRightSimple";

const OperationUnitsBanner = ({ operationUnitsBanner = null }) => {
    if (!operationUnitsBanner) {
        return null;
    }

    return (
        <section className={styles.operationUnitsBanner}>
            <div className={styles.text}>
                <h1>{operationUnitsBanner.perex}</h1>
                <div dangerouslySetInnerHTML={{__html: operationUnitsBanner.content}}></div>
            </div>
            {operationUnitsBanner?.photo ? (
                <div className={styles.image}>
                    <Image
                        photo={operationUnitsBanner.photo}
                        size={"479x235"}
                        type={"png"}
                        useNextImage={true}
                        priority={true}
                    />
                </div>
            ) : null}
            {operationUnitsBanner?.url ? (
                <Link
                    href={operationUnitsBanner.url}
                >
                    <a className={styles.link}>
                        Zobraziť predajne
                        <span className={styles.icon}>
                            <IconArrowRightSimple/>
                        </span>
                    </a>
                </Link>
            ) : null}
        </section>
    )
}

export default OperationUnitsBanner;
