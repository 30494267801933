import { Carousel } from "antd";

const Image = dynamic(() =>
  import("grandus-lib/components-atomic/image/Image")
);
import styles from "./Banners.module.scss";
import dynamic from "next/dynamic";
import {useEffect, useRef, useState} from "react";
import IconArrowRight from "components/_others/icons/IconArrowRight";
import {isEmpty} from "lodash";

const ArrowLeft = (props) => {
  const { className, style, onClick } = props;
  return (
    <button type="button" className={className + " left"} onClick={onClick}>
       {/*<i className="fas fa-chevron-left"></i>*/}
    </button>
  );
};

const ArrowRight = (props) => {
  const { className, style, onClick } = props;
  return (
    <button type="button" className={className + " right"} onClick={onClick}>
       {/*<i className="fas fa-chevron-right"></i>*/}
    </button>
  );
};


const Banners = ({ banners }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const dotsWrapRef = useRef(null);
  const activeDotRef = useRef(null);
  const CAROUSEL_SETTINGS = {
    dots: true,
    arrows: true,
    infinite: true,
    draggable: true,
    prevArrow: <ArrowLeft />,
    nextArrow: <ArrowRight />,
    autoplay: true,
    autoplaySpeed: 4000,
    afterChange: (current) => {
      setActiveIndex(current);
    },
  };

  const mobileBanners = banners.filter((banner) => {
    return banner.thumbnail;
  });

  useEffect(() => {
    const dotsWrap = dotsWrapRef.current;
    const activeDot = activeDotRef.current;

    if (dotsWrap && activeDot) {
      const wrapRect = dotsWrap.getBoundingClientRect();
      const dotRect = activeDot.getBoundingClientRect();

      if (dotRect.left < wrapRect.left) {
        dotsWrap.scrollLeft -= (wrapRect.left - dotRect.left);
      }
      else if (dotRect.right > wrapRect.right) {
        dotsWrap.scrollLeft += (dotRect.right - wrapRect.right);
      }
    }
  }, [activeIndex]);

  return (
    <>
      <div className={styles.desktopBanners}>
        <Carousel className={"promo-slider"} {...CAROUSEL_SETTINGS}>
          {banners.map((banner, index) => {
            const { id, url, photo } = banner;
            if(isEmpty(photo)){
              return ""
            }
            return (
              <a href={url} key={`banners-${id}-${index}`}>
                <Image photo={photo} size={"1410x438"} type={"jpg"} />
              </a>
            );
          })}
        </Carousel>
        <div className={styles.dotsSlider}>
          <div className={styles.dotsWrap} ref={dotsWrapRef}>
            {banners.map((banner, index) => {
              return (
                <div
                  key={`banner-${index}`}
                  ref={activeIndex == index ? activeDotRef : null}
                  className={
                  `${styles.dot}
                   ${activeIndex == index ? styles.active : ""}`
                  }
                >
                  <span>
                    {banner?.value}
                  </span>
                  <IconArrowRight />
                </div>
              );
            })}
          </div>

        </div>
      </div>

      <div className={styles.mobileBanners}>
        <Carousel className={"promo-slider"} {...CAROUSEL_SETTINGS}>
          {mobileBanners.map((banner, index) => {
            const { id, url, thumbnail } = banner;
            return (
              <a href={url} key={`banners-${id}-${index}`}>
                <Image photo={thumbnail} size={"480x480"} type={"jpg"} />
              </a>
            );
          })}
        </Carousel>
      </div>
    </>
  );
};

export default Banners;
