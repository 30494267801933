import {Carousel} from "antd";
import styles from "./Benefits.module.scss"


const Benefits = () => {
    const sliderOptions = {
        dots: {
            className: `slick-dots slick-dots-bottom ${styles.benefitsDots}`
        },
        arrows: false,
        infinite: true,
        draggable: true,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                },
            },
        ]
    }

    return (
        <section className="icons pb-4">
            <div className="container">
                <div className={styles.benefitsContainer}>
                    <Carousel
                        className={"benefits-slider"}
                        {...sliderOptions}
                    >
                        <div className="item">
                            <div className={styles.benefitsItem}>

                                <div className="icon">
                                    <img src="/img/delivery.svg" alt="delivery" className="svg"/>
                                </div>
                                <div className={styles.text}>
                                    <p>Osobný odber v špecializovaných predajniach</p>
                                </div>
                            </div>
                        </div>

                        <div className="item">
                            <div className={styles.benefitsItem}>
                                <div className="icon">
                                    <img src="/img/location.svg" alt="location" className="svg"/>
                                </div>
                                <div className={styles.text}>
                                    <p>Prevádzky po celom Slovensku</p>
                                </div>
                            </div>
                        </div>

                        <div className="item">
                            <div className={styles.benefitsItem}>
                                <div className="icon">
                                    <img
                                        src="/img/delivery-express.svg"
                                        alt="delivery expres"
                                        className="svg"
                                    />
                                </div>
                                <div className={styles.text}>
                                    <p>Expresné dodanie do 24 h</p>
                                </div>
                            </div>
                        </div>

                        <div className="item">
                            <div className={styles.benefitsItem}>
                                <div className="icon">
                                    <img src="/img/inventory.svg" alt="inventory" className="svg"/>
                                </div>
                                <div className={styles.text}>
                                    <p>Tisíce produktov k okamžitému odoslaniu</p>
                                </div>
                            </div>
                        </div>
                    </Carousel>
                </div>
            </div>
        </section>
    );
};

export default Benefits;