import styles from "./index/index.page.module.scss";
import { indexPage } from "grandus-lib/utils/fetches";
import { useState, useEffect } from "react";
import { Carousel } from "antd";

import Link from "next/link";

import EnhancedEcommerce from "grandus-lib/utils/ecommerce";
import TagManager from "grandus-lib/utils/gtag";
import { get } from "lodash";

import Benefits from "components/Benefits";
import Banners from "components/Banners";

import dynamic from "next/dynamic";
import isEmpty from "lodash/isEmpty";
import BannerCard from "../components/index/bannerCard/BannerCard";
import OperationUnitsBanner from "../components/operationUnits/banner/OperationUnitsBanner";
const MetaData = dynamic(() =>
  import("grandus-lib/components-atomic/MetaData")
);
const Content = dynamic(() =>
  import("grandus-lib/components-atomic/content/Content")
);
const ProductCard = dynamic(() =>
  import("components/product/card/ProductCard")
);
const ProductCardSimple = dynamic(() =>
  import("components/product/card/ProductCardSimple")
);
const Image = dynamic(() =>
  import("grandus-lib/components-atomic/image/Image")
);

const ArrowLeft = (props) => {
  const { className, style, onClick } = props;
  return (
    <button type="button" className={className + " left"} onClick={onClick}>
      {/* <i className="fas fa-chevron-left"></i> */}
    </button>
  );
};

const ArrowRight = (props) => {
  const { className, style, onClick } = props;
  return (
    <button type="button" className={className + " right"} onClick={onClick}>
      {/* <i className="fas fa-chevron-right"></i> */}
    </button>
  );
};

const Index = (props) => {
  const {
    meta = {},
    banners = [],
    promo = [],
    cta = [],
    products = [],
    productsNew = [],
    productsFav = [],
    bannersNew = [],
    operationUnitsBanner = null,
  } = props;

  const [activeSection, setActiveSection] = useState(1);

  const sliderSettingsProducts = {
    dots: true,
    arrows: true,
    infinite: false,
    draggable: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    // customPaging: function () {
    //   return "";
    // },
    prevArrow: <ArrowLeft />,
    nextArrow: <ArrowRight />,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2,
          arrows: false,
        },
      },
    ],
  };
  const sliderSettingsBanners = {
    ...sliderSettingsProducts,
    slidesToShow: 4,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          arrows: false,
          centerMode: true
        },
      },
    ],
  };

  useEffect(() => {
    TagManager.push(
      EnhancedEcommerce.impressions(products, "homepage favourite products")
    );
    TagManager.push(
      EnhancedEcommerce.impressions(productsNew, "homepage new products")
    );
  }, []);
  meta.options = {
    image: {
      dimensions: "1200",
      width: 1200,
      height: -1,
    },
  };
  meta.photo = get(banners, "[0].photo", {});

  return (
    <section className={styles.wrapper + " pb-0"}>
      <MetaData {...meta} />
      <section className="promo">
        <div className="container promo-index">
          <Banners banners={banners} />
        </div>
      </section>

      <Benefits />

      <div className="container">
        <OperationUnitsBanner operationUnitsBanner={operationUnitsBanner} />
      </div>

      <section className={"product-slider-section py-0"}>
        <div className="container">
          <div className={"wrapper visible bannerWrap d-none d-md-block"}>
            <Carousel
              className="product-slider"
              autoplay
              {...sliderSettingsBanners}
              style={{ padding: "50px 0" }}
            >
              {bannersNew.map((item, index) => {
                if(isEmpty(item?.photo)){
                  return ""
                }
                return(
                  <div key={`promo-item-${item?.id}-${index}`}>
                    <BannerCard item={item} index={index} />
                  </div>
                  )
              })}
            </Carousel>
          </div>
          <div className={"d-md-none bannerWrap-mobile"}>
            {bannersNew.map((item, index) => {
              if(isEmpty(item?.photo)){
                return ""
              }
              return(
                <div key={`promo-item-${item?.id}-${index}-mobile`}>
                  <BannerCard item={item} index={index} />
                </div>
              )
            })}
          </div>
        </div>
      </section>

      <section className="product-slider-section">
        <div className="container">
          <div className="text-center">
            <h2>Aktuálna ponuka</h2>
          </div>
          <div className="wrapper visible p-0">
            <Carousel
              className="product-slider"
              autoplay
              {...sliderSettingsProducts}
              style={{ padding: "50px 0" }}
            >
              {products.map((product, index) => {
                if(isEmpty(product?.photo)){
                  return ""
                }
                return (
                  <div key={`products-${product.id}-${index}`}>
                    <ProductCardSimple {...product} />
                  </div>
                );
              })}
            </Carousel>
          </div>
        </div>
      </section>

      <section className="cta pt-3 pb-0">
        <div className="container">
          <div className="wrapper">
            <div className="row">
              {/* <div className="col-md-12">
                <a href="#">
                  <picture>
                    <source
                      media="(max-width: 767px)"
                      srcSet="/img/promo1.jpg"
                    />
                    <img src="/img/promo-full.png" alt="cta" />
                  </picture>
                </a>
              </div> */}

              {promo.map((item, index) => {
                const { url, photo } = item;
                return (
                  <div
                    className="col-md-6"
                    key={`promo-banner-${item?.id}-${index}`}
                  >
                    <Link href={url ? url : "/"} scroll={true}>
                      <a>
                        <Image photo={photo} size={"685x200"} type={"jpg"} />
                      </a>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>

      <section className="product-slider-section pt-3">
        <div className="container">
          <div className="slider-tabs">
            <div
              className={`item ${activeSection == 1 ? `active` : ""}`}
              onClick={() => {
                setActiveSection(1);
              }}
            >
              Odporúčané
            </div>
            <div
              className={`item ${activeSection == 2 ? `active` : ""}`}
              onClick={() => {
                setActiveSection(2);
              }}
            >
              Obľúbené
            </div>
          </div>
          <div className="sliders">
            <div className={`wrapper ${activeSection == 1 ? `visible` : ""}`}>
              <Carousel
                autoplay
                className="product-slider"
                {...sliderSettingsProducts}
                style={{ padding: "50px 0" }}
              >
                {productsFav.map((product, index) => {
                  if(isEmpty(product?.photo)){
                    return ""
                  }
                  return (
                    <div key={`products-fav-${product.id}-${index}`}>
                      <ProductCardSimple {...product} />
                    </div>
                  );
                })}
              </Carousel>
            </div>
            <div className={`wrapper ${activeSection == 2 ? `visible` : ""}`}>
              <Carousel
                autoplay
                className="product-slider"
                {...sliderSettingsProducts}
                style={{ padding: "50px 0" }}
              >
                {productsNew.map((product, index) => {
                  if(isEmpty(product?.photo)){
                    return ""
                  }
                  return (
                    <div key={`products-new-${product.id}-${index}`}>
                      <ProductCardSimple {...product} />
                    </div>
                  );
                })}
              </Carousel>
            </div>
          </div>
        </div>
      </section>

      <section className="partners mb-0">
        <Carousel
          className="partners-slider"
          {...{ ...sliderSettingsProducts, slidesToShow: 6, arrows: false }}
        >
          <div>
            <div className="wrap">
              <img src="/img/logos/partner1.svg" alt="partner" />
            </div>
          </div>
          <div>
            <div className="wrap">
              <img src="/img/logos/partner2.svg" alt="partner" />
            </div>
          </div>
          <div>
            <div className="wrap">
              <img src="/img/logos/partner3.svg" alt="partner" />
            </div>
          </div>
          <div>
            <div className="wrap">
              <img src="/img/logos/partner4.svg" alt="partner" />
            </div>
          </div>
          <div>
            <div className="wrap">
              <img src="/img/logos/partner5.svg" alt="partner" />
            </div>
          </div>
          <div>
            <div className="wrap">
              <img src="/img/logos/partner6.svg" alt="partner" />
            </div>
          </div>
          <div>
            <div className="wrap">
              <img src="/img/logos/partner7.svg" alt="partner" />
            </div>
          </div>
          <div>
            <div className="wrap">
              <img src="/img/logos/partner8.svg" alt="partner" />
            </div>
          </div>
        </Carousel>

        {/* <a href="#" className="button inverted">
          Výrobcovia
        </a> */}
      </section>

      {!isEmpty(cta) ? (
        <section className="cta">
          <div className="container-fluid">
            <div className="wrapper">
              <div className="row">
                {cta.map((item, index) => {
                  const { url, photo } = item;
                  return (
                    <div
                      className="col-md-6"
                      key={`cta-banner-${item?.id}-${index}`}
                    >
                      <Link href={url ? url : "/"} scroll={true}>
                        <a>
                          <Image photo={photo} size={"685x402"} type={"jpg"} />
                        </a>
                      </Link>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </section>
      ) : ""}
    </section>
  );
};

export async function getServerSideProps(context) {
  return indexPage.serverSideProps(context);
}

export default Index;
