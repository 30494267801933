import Link from "next/link";
import styles from "./BannerCard.module.scss";
import dynamic from "next/dynamic";
import IconArrowRightSimple from "components/_others/icons/IconArrowRightSimple";
const Image = dynamic(() =>
	import("grandus-lib/components-atomic/image/Image")
);

const BannerCard = ({item, index}) => {
	const isSecond = (index + 1) % 2 == 0;
	const isThird = (index + 1) % 3 == 0;

	return(
		<div className={`${styles.card} ${isSecond ? styles.isSecond : "" } ${isThird ? styles.isThird : ""}`}>
			<div className={styles.image}>
				<Image photo={item?.photo} size={"300x300"} type={"png"} />
			</div>
			<div className={styles.content}>
				{item?.value ? (
					<span style={styles.subtitle}>{item?.value}</span>
				) : ""}
				<h3>
					{item?.title ? (
						<>
							{item?.title}
						</>
					) : ""}
				</h3>
				{item?.url ? (
					<Link href={item?.url}>Zisti viac informácií o akcii!</Link>
				) : ""}
				<span className={styles.icon}>
					<IconArrowRightSimple />
				</span>
			</div>
		</div>
	)
}
export default BannerCard;